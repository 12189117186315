import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
declare var Metro:any;
declare var $:any;


@NgModule({

  imports: [
    CommonModule
  ]
})

export class SharedModule {

  public ConvertKiloToTonne(WeightInKgs:number)
  {
    return (WeightInKgs/1000).toLocaleString(
      undefined, // leave undefined to use the visitor's browser
      // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: 3 }
    );
  };

  public ConvertTonneToKilo(WeightInKgs:number)
  {
    return (WeightInKgs*1000).toLocaleString(
      undefined, // leave undefined to use the visitor's browser
      // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: 0 }
    );
  };

  public ConvertWeightToDisplay(WeightValue: number) {
    let ret =  this.ConvertKiloToTonne(WeightValue);
    return ret;
  }

  public ConvertWeightToDB(WeightValue: number) {
    let ret =  this.ConvertTonneToKilo(WeightValue);
    return ret;
  }

  public GetSystemType () {

    let ret   = localStorage.getItem('systemType');
    return ret;
  }

  public metroAlert(message:string, title:string, error:boolean = false, acceptFunction:any = null){
    let thisObj = this;
    Metro.dialog.create({
      title: title,
      clsDialog: error ? "alert" : "success",
      content: "<div>" + message + "</div>",
      actions: [
        {
          caption: "OK",
          cls: "js-dialog-close",
          onclick: acceptFunction
        }
      ]
    });
  }

  public metroYesNo(title : string, message : string, parent:any=null, acceptFunction:any = null){
    Metro.dialog.create({
        title: title,
        content: "<div>" + message + "</div>",
        actions: [
            {
                caption: "Yes",
                cls: "js-dialog-close alert",
                onclick: function(){
                    acceptFunction(parent);
                }
            },
            {
                caption: "No",
                cls: "js-dialog-close",
                onclick: function(){
                  //Do nothing
                }
            }
        ]
    });

    
}

public metroBidYesNo(title : string, message : string, parent:any=null, acceptFunction:any = null, rejectFunction:any = null){
  Metro.dialog.create({
      title: title,
      content: "<div>" + message + "</div>",
      actions: [
          {
              caption: "Yes",
              cls: "js-dialog-close alert",
              onclick: function(){
                  acceptFunction(parent);
              }
          },
          {
              caption: "No",
              cls: "js-dialog-close",
              onclick: function(){
                rejectFunction(parent);
              }
          }
      ]
  });
}


  public base64ToBlob(base64:string, mimetype:string, slicesize:number = 512) : Blob | MediaSource
  {

    mimetype = mimetype || '';
    slicesize = slicesize || 512;
    var bytechars = atob(base64);
    var bytearrays = [];
    for (var offset = 0; offset < bytechars.length; offset += slicesize) {
      var slice = bytechars.slice(offset, offset + slicesize);
      var bytenums = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        bytenums[i] = slice.charCodeAt(i);
      }
      var bytearray = new Uint8Array(bytenums);
      bytearrays[bytearrays.length] = bytearray;
    }
    return new Blob(bytearrays, {type: mimetype});
  };

  public ReportPath(reportName:string)
  {
    return "d:\\Customer\\Navara\\Reports\\" + reportName;
    //return "//var/www//reports//" + reportName;

  };



}

export class AppSettings {

  public static STANDARD_PAGE_REFRESH_MS = 15000;

  public static MIN_LOT_COUNT = 1;
  public static MAX_LOT_COUNT = 10;

  public static MIN_LOT_SIZE = 30;
  public static MAX_LOT_SIZE = 5000;

  public static MIN_RESERVE_PRICE = 20;
  public static MAX_RESERVE_PRICE = 110;

  public static MIN_BUY_IT_NOW_PRICE = 20;
  public static MAX_BUY_IT_NOW_PRICE = 110;

  public static MIN_BID_AMOUNT = 20;
  public static MAX_BID_AMOUNT = 110;

  public static MIN_BID_TONNES = 30;
  public static MAX_BID_TONNES = 5000;
}



