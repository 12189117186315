import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environment/environment';


export function getBaseUrl() {
  //Navara
  //return "http://10.100.10.51:81/";
  //CEMEX
  //return "https://cemex.individual-systems.net:4331/";

  //return "http://127.0.0.1:5000/";
  //return "https://angtest.api.individual-systems.net/";
  //Camgrain - Live
  return "https://camgrain.individual-systems.net:4331/";
    //This now comes from the environment folder.
   // return environment.apiUrl;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
