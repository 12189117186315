import {Component, Inject, OnInit, NgIterable, ViewChild, ElementRef } from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {HttpClient, HttpParams} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detail-train',
  templateUrl: './detail-train.component.html',
  styleUrls: ['./detail-train.component.css']
})
export class DetailTrainComponent implements OnInit{
  
  dataLoaded = false;
  wagonData : any;
  reasons : any;
  public order:string | null = "";
  
  private sm : SharedModule;

  constructor (private route: ActivatedRoute,private http: HttpClient, @Inject('BASE_URL')private baseUrl:string, public shared:SharedModule, private _router : Router)
  {
    this.sm = shared;
  }

  ngOnInit() {

    let counter : number = 0;

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.order = params.get('order');
      if (this.order) {
         counter ++;
         this.dataLoaded = counter == 3;
      }
    });

   
    let params = new HttpParams().set("trainID",this.order != null ? this.order : "")
    this.http.get(this.baseUrl + 'Rail/GetWagonData', {params:params} ).subscribe(
      (data:any) => {
        this.wagonData = data; 
        counter++;
        this.dataLoaded = counter == 3});

    
    this.http.get(this.baseUrl + 'Rail/GetReasons').subscribe(
          (data:any) => {
            this.reasons = data; 
            counter++;
            this.dataLoaded = counter == 3});
    

  }

  public saveFormData()
  {
    let formData:FormData = new FormData();
    formData.set("TrainID",this.order != null ? this.order : "");
   
    
      this.http
        .post(this.baseUrl + 'Rail/SaveTrain', formData)
        .subscribe({
          next: (response) => this.saveFinished(),
          error: (error) => this.sm.metroAlert("There was an error and your site settings have not been saved.<br/><br/>" + error.message, "Failed", true),
        });
     
    
    
  }
  

  saveFinished()
  {
    this.sm.metroAlert("Train Details saved.","Save Data");
    this._router.navigate(['/'])
  }
    
  

 

}
