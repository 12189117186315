import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorPageComponent } from "./theme/error-page/error-page.component";
import { LoginformComponent } from "./loginform/loginform.component";
import { HomeComponent } from "./home/home.component";
import { DetailTrainComponent } from './rail/detail-train/detail-train.component';
import { WeighbridgeComponent } from './milling/weighbridge/weighbridge.component';
import { StartstopComponent } from './milling/startstop/startstop.component';
import { PackinglineComponent } from './milling/packingline/packingline.component';
import { RelocationComponent } from './milling/relocation/relocation.component';
import { StockadjustComponent } from './milling/stockadjust/stockadjust.component';
import { WeightOnComponent } from './milling/weight-on/weight-on.component';
import { WeightOffComponent } from './milling/weight-off/weight-off.component';
import { WeightOnOffComponent } from './milling/weight-on-off/weight-on-off.component';
import { GrnComponent } from './milling/grn/grn.component';
import { ReportviewerComponent } from './shared/reportviewer/reportviewer.component';
import { TestingComponent } from './milling/testing/testing.component';
import { ShiftrecordsComponent } from './milling/shiftrecords/shiftrecords.component';
import { PlantdataComponent } from './milling/plantdata/plantdata.component';
import { PackingdataComponent } from './milling/packingdata/packingdata.component';
import { ViewAuctionComponent } from "./grain/viewauction/viewauction.component";
import {GrainComponent} from "./grain/home/home.component";
import {AuctionBuyComponent} from "./grain/auction-buy/auction-buy.component";
import {AuctionSellComponent} from "./grain/auction-sell/auction-sell.component";
import { AnalysisComponent } from './milling/analysis/analysis.component';
import { LocationComponent } from './milling/location/location.component';
import { AnalysisAddComponent } from './milling/analysis-add/analysis-add.component';
import { ActiveconnectionsComponent } from './milling/activeconnections/activeconnections.component';
import {AuctionCreateComponent} from "./grain/auction-create/auction-create.component";
import {AuctionBidComponent} from "./grain/auction-bid/auction-bid.component";
import {AuctionTermsComponent} from "./grain/auction-terms/auction-terms.component";
import {SwitchUserComponent} from "./grain/switch-user/switch-user.component";
import { DayreportComponent } from './milling/reports/dayreport/dayreport.component'; 


const routes: Routes = [

  { path: 'login', component: LoginformComponent },
  { path: '', component: HomeComponent },
  { path: 'reportviewer/:reportname', component:ReportviewerComponent},
  //Rail
  { path: 'rail/traindetail/:order', component: DetailTrainComponent},
  //Milling

  //Testing
  { path: 'milling/testing/home', component:TestingComponent},
  { path: 'milling/testing/weighbridge', component: WeighbridgeComponent},
  { path: 'milling/testing/startstop', component: StartstopComponent},
  { path: 'milling/testing/packingline', component: PackinglineComponent},
  { path: 'milling/testing/relocation', component: RelocationComponent},
  { path: 'milling/testing/stockadjust', component: StockadjustComponent},
  { path: 'milling/testing/weightoff', component: WeightOffComponent},
  { path: 'milling/testing/weighton', component: WeightOnComponent},
  { path: 'milling/testing/weightonoff', component: WeightOnOffComponent},
  { path: 'milling/testing/grn', component: GrnComponent},

  {path: 'milling/shiftRecords', component: ShiftrecordsComponent},
  {path: 'milling/plantData', component: PlantdataComponent},
  {path: 'milling/packingData', component: PackingdataComponent},
  {path: 'milling/analysisTest', component: AnalysisComponent},
  {path: 'milling/analysisAdd', component: AnalysisAddComponent},

  {path : 'milling/location', component: LocationComponent},
  {path : 'milling/reports/dayreport', component: DayreportComponent},

  {
    path: 'grain/auction/view',
    component: GrainComponent,
    children: [
      {
        path: '',
        component: ViewAuctionComponent,
        outlet: 'grain-outlet'
      },

    ]
  },

  {
    path: 'grain/auction/buy',
    component: GrainComponent,
    children: [
      {
        path: '',
        component: AuctionBuyComponent,
        outlet: 'grain-outlet'
      },

    ]
  },

  {
    path: 'grain/auction/sell',
    component: GrainComponent,
    children: [
      {
        path: '',
        component: AuctionSellComponent,
        outlet: 'grain-outlet'
      },

    ]
  },

  {
    path: 'grain/auction/sell/create',
    component: GrainComponent,
    children: [
      {
        path: '',
        component: AuctionCreateComponent,
        outlet: 'grain-outlet'
      },

    ]
  },
  {
    path: 'grain/auction/buy/bid',
    component: GrainComponent,
    children: [
      {
        path: '',
        component: AuctionBidComponent,
        outlet: 'grain-outlet'
      },

    ]
  },
  {
    path: 'grain/auction/terms',
    component: GrainComponent,
    children: [
      {
        path: '',
        component: AuctionTermsComponent,
        outlet: 'grain-outlet'
      },

    ]
  },
  {
    path: 'grain/switchUser',
    component: GrainComponent,
    children: [
      {
        path: '',
        component: SwitchUserComponent,
        outlet: 'grain-outlet'
      },

    ]
  },


  { path: '**', component: ErrorPageComponent }
]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
