<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
</ul>

<h1>Add Analysis Record</h1>

<ng-container *ngIf="!dataLoaded">
    <div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your
        data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
    </ng-container>
    

    <ng-container *ngIf="dataLoaded">
        <form name="edit" id="edit-buy" class="" action="" method="post">
            <div class="grid">
                <div class="form-group" >
                    <div class="row">
                        <div class="cell-5">
                            <label>Location</label>
                            <select data-role="select" id="System"   [(ngModel)]="selectedValue" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let c of analysisLoc" [ngValue]="c.code">{{c.description}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="cell-5">
                            <label>Kiln</label>
                            <select data-role="select" id="Kiln"   [(ngModel)]="selectedKiln" [ngModelOptions]="{standalone: true}">
                              <option *ngFor="let c of kiln" [ngValue]="c.id">{{c.text}}</option>
                            </select>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="cell-1">
                            <label>Moisture</label>
                            <input id="moisture" type="number" class="metro-input"  [(ngModel)]="moisture" [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                    <div class="row">
                        <div class="cell-1">
                            <label>KG/HL (SG)</label>
                            <input id="specificG" type="number" class="metro-input"  [(ngModel)]="sg" [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="dialog-actions">
                <button class="button" type="reset" value="Reset" (click)="cancelForm()">Cancel</button>
                <button class="button primary" (click)="saveFormData()">Save</button>
              </div>
        </form>
        
    </ng-container>