import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;


@Component({
  selector: 'app-plantdata',
  templateUrl: './plantdata.component.html',
  styleUrls: ['./plantdata.component.css']
})
export class PlantdataComponent implements OnInit {
  systemTypes = [
    {id: "WHAT_IF", name: "WHAT_IF"},
    {id: "PILOT", name: "PILOT"},
    {id: "TRAIN", name: "TRAIN"},
    {id: "DEV", name: "DEV"},
 ];
 selectedValue : any = null;
 dataLoaded : boolean = false;
 pltData : any = null;
 pltDescription : any = null;
 poNumber : any = null;




  private sm : SharedModule;

  public siteSystemToUse : string = "";

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
     
    

  }

  ngOnInit(){
    let counter : number = 0;
    this.http.get(this.baseUrl + 'SiteSettings/systemToUse', {responseType: 'text'}).subscribe(
      data => {this.siteSystemToUse = data; this.selectedValue =this.siteSystemToUse; counter++; this.dataLoaded = counter == 2;});

      this.http.get(this.baseUrl + 'Milling/GetPlantData').subscribe(
        data => {
          this.pltData = data; 
          if (this.pltData != null)
          {
            //Only ONE record returned from API
            this.pltDescription = this.pltData[0].description;
            this.poNumber = this.pltData[0].ponumber;
          }
          counter++;
          this.dataLoaded = counter == 2;
          });

      
      
          
      
  }

  validate(formData: FormData) {
    let errorMessage:string = "";
   if (formData.get("item1")?.toString() == "")
     errorMessage = "Put some data in Dummy !!";

    //if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) <= 0){
    //  errorMessage = "You must enter a positive value for your bid amount.";      
    //} else if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) < AppSettings.MIN_BID_AMOUNT && !this.selectedIsForLots) {
    //  errorMessage = "The bid amount must be greater or equal to " + AppSettings.MIN_BID_AMOUNT;
    //} 
    return errorMessage;
  }

  public cancelForm()
  {
    this.sm.metroAlert("Cancelled all changes","Cancel");
    this._router.navigate(['/'])
  }


  public saveFormData()
  {
    let bAllDataSaved = false;
    let formData:FormData = new FormData();
    formData.set("SystemToUse",this.selectedValue);
    let errorString = this.validate(formData);

    if (errorString.length == 0) {
      this.http
        .post(this.baseUrl + 'SiteSettings/SaveSystem', formData)
        .subscribe({
          next: (response) => bAllDataSaved = true,
          error: (error) => this.sm.metroAlert("There was an error and your site settings have not been saved.<br/><br/>" + error.message, "Failed", true),
        });
       
       
        let plantData:FormData = new FormData();
        plantData.set("Description",this.pltDescription);
        plantData.set("PONumber",this.poNumber);
        //Save the plant data
        this.http
        .post(this.baseUrl + 'Milling/SavePlantData', plantData)
        .subscribe({
          next: (response) => bAllDataSaved = true,
          error: (error) => this.sm.metroAlert("There was an error and your site settings have not been saved.<br/><br/>" + error.message, "Failed", true),
        });

        this.saveFinished()

    }else{
      this.sm.metroAlert(errorString, "The dialog contains errors", true);
    }
    
  }
  

  saveFinished()
  {
    this.sm.metroAlert("System settings saved.","Save Data");
    this._router.navigate(['/'])
  }
}


