import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;


@Component({
  selector: 'app-packingdata',
  templateUrl: './packingdata.component.html',
  styleUrls: ['./packingdata.component.css']
})
export class PackingdataComponent  implements OnInit {
  dataLoaded = false;
  worksOrders : any;
  locations : any;
  selectedWO = "";
  selectedValue ="";
  txtProjectOwner = "";
  worksOrder : any;
  
  private sm : SharedModule;
  
  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
  }

  ngOnInit(){
    let counter : number = 0;
    this.http.get(this.baseUrl + 'Milling/ListOpenWorksOrders').subscribe(
      (data:any) => {
        this.worksOrders = data; 
        this.selectedWO =""; 
        counter++;
        this.dataLoaded = counter == 2;});
    
    this.http.get(this.baseUrl + 'Milling/ListPackingLocations').subscribe(
          (data:any) => {
            this.locations = data; 
            this.selectedValue =""; 
            counter++;
            this.dataLoaded = counter == 2;});
    
   
      
  }

  validate(formData: FormData) {
    let errorMessage:string = "";
    if ((parseInt(formData.get("weight")?.toString() ?? "0") ??0) > 100)
      errorMessage = "Weight too high";

    //if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) <= 0){
    //  errorMessage = "You must enter a positive value for your bid amount.";      
    //} else if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) < AppSettings.MIN_BID_AMOUNT && !this.selectedIsForLots) {
    //  errorMessage = "The bid amount must be greater or equal to " + AppSettings.MIN_BID_AMOUNT;
    //} 
    return errorMessage;
  }


  public saveFormData()
  {
    let formData:FormData = new FormData();
    formData.set("EventSource",'UMBRA');
    formData.set("WorksOrder",this.selectedWO);
    formData.set("NoOfBags",$('#NoOfBags').val() != '' ? $('#NoOfBags').val() : '');
    formData.set("Weight",$('#weight').val() != '' ? $('#weight').val() : null);
    formData.set("Warehouse","WIPB1");
    formData.set("Location",this.selectedValue);

    formData.set("PartID",$('#partID').val() != '' ? $('#partID').val() : '');
    formData.set("PalletID",$('#palletID').val() != '' ? $('#palletID').val() : '');
    formData.set("ContainerID",$('#ContainerID').val() != '' ? $('#ContainerID').val() : '');
    
    
    let errorString = this.validate(formData);

    if (errorString.length == 0) {
      this.http
        .post(this.baseUrl + 'Navara/SavePackingRecord', formData)
        .subscribe({
          next: (response) => this.saveFinished(),
          error: (error) => this.sm.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
        });
      //this.reload();  

    }else{
      this.sm.metroAlert(errorString, "The dialog contains errors", true);
    }
    
  }


  saveFinished()
  {
    this.sm.metroAlert("Save succesful.","Title");
    this._router.navigate(['/'])
  }
}
