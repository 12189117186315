import { Component, Inject, Injectable } from '@angular/core';
import { AuthService } from "../../auth/auth.service";
import { HttpClientModule } from '@angular/common/http';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  siteImage : string = ""
  
  constructor(public auth: AuthService, private http:HttpClient,@Inject('BASE_URL') private baseUrl: string) {
  }
  
  ngOnInit(){
    
    this.http.get(this.baseUrl + 'SiteSettings/siteLogo', {responseType: 'text'}).subscribe(
      data => this.siteImage = data);
  }
   


  logout() {
    this.auth.logout();
  }
}
