<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
    <li><a [routerLink]="['/milling/testing/home']" [routerLinkActive]="['active']"><span class="mif-tools icon"></span>Testing Main Page</a></li>  
</ul>

<h1>Weight On</h1>

<form>
    <div class="row mb-2">
        <label class="cell-sm-2">Event Source</label>
        <div class="cell-sm-10">
            <input id="eventSource" type="text" class="metro-input">
        </div>
    </div>

    <div class="row mb-2">
        <label class="cell-sm-2">Weight</label>
        <div class="cell-sm-10">
            <input id="weight" type="number" class="metro-input">
        </div>
    </div>
    <div class="row mb-2">
        <label class="cell-sm-2">Location On</label>
        <div class="cell-sm-10">
            <input id="locationOn" type="text" class="metro-input">
        </div>
    </div>
    
    <div class="row mb-2">
        <label class="cell-sm-2">Warehouse On</label>
        <div class="cell-sm-10">
            <input id="warehouseOn" type="text" class="metro-input" value="WIPB1">
        </div>
    </div>

    <div class="row mb-2">
        <label class="cell-sm-2">Part ID</label>
        <div class="cell-sm-10">
            <input id="partID" type="text" class="metro-input">
        </div>
    </div>

    <div class="row mb-2">
        <label class="cell-sm-2">Reference</label>
        <div class="cell-sm-10">
            <input id="reference" type="text" class="metro-input">
        </div>
    </div>

    <div class="row">
        <div class="cell">
            <button class="button primary" (click)="saveFormData()">Save</button>
        </div>
    </div>
</form>