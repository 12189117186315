<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
    <li><a [routerLink]="['/milling/testing/home']" [routerLinkActive]="['active']"><span class="mif-tools icon"></span>Testing Main Page</a></li>  
</ul>

<h1>Weighbridge</h1>

<form>
    <div class="row mb-2">
        <label class="cell-sm-2">Order No</label>
        <div class="cell-sm-10">
            <input id="order" type="text" class="metro-input">
        </div>
    </div>

    <div class="row mb-2">
        <label class="cell-sm-2">Weight</label>
        <div class="cell-sm-10">
            <input id="weight" type="number" class="metro-input">
        </div>
    </div>

    <div class="row mb-2">
        <label class="cell-sm-2">Location On</label>
        <div class="cell-sm-10">
            <input id="locationOn" type="text" class="metro-input">
        </div>
    </div>
    
    <div class="row mb-2">
        <label class="cell-sm-2">Warehouse On</label>
        <div class="cell-sm-10">
            <input id="warehouseOn" type="text" class="metro-input" value="WIPB1">
        </div>
    </div>

    
    <div class="row mb-2">
        <label class="cell-sm-2">Location Off</label>
        <div class="cell-sm-10">
            <input id="locationOff" type="text" class="metro-input">
        </div>
    </div>
    
    <div class="row mb-2">
        <label class="cell-sm-2">Warehouse Off</label>
        <div class="cell-sm-10">
            <input id="warehouseOff" type="text" class="metro-input" value="">
        </div>
    </div>
    
    

    <div class="row mb-2">
        <label class="cell-sm-2">Vehicle</label>
        <div class="cell-sm-10">
            <input id="vehicle" type="text" class="metro-input">
        </div>
    </div>
    
    <div class="row mb-2">
        <label class="cell-sm-2">Trailer</label>
        <div class="cell-sm-10">
            <input id="trailer" type="text" class="metro-input" value="">
        </div>
    </div>
   

    <div class="row">
        <div class="cell">
            <button class="button primary" (click)="saveFormData()">Save</button>
        </div>
    </div>
</form>