import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;


@Component({
  selector: 'app-grn',
  templateUrl: './grn.component.html',
  styleUrls: ['./grn.component.css']
})
export class GrnComponent implements OnInit{
  private sm : SharedModule;
  pltData : any = null;
  poNumber : any = null;
  dataLoaded : boolean = false;

  warehouse : string = "";
  location : string = "";
  weight: number = 0;
  referenceItem : any = null;




  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
  }


  ngOnInit(){
      this.http.get(this.baseUrl + 'Milling/GetPlantData').subscribe(
        data => {
          this.pltData = data; 
          if (this.pltData != null)
          {
            //Only ONE record returned from API
            this.poNumber = this.pltData[0].ponumber;
          }
          this.dataLoaded = true;
          });

      
          
      
  }

  validate(formData: FormData) {
    let errorMessage:string = "";
   if (formData.get("item1")?.toString() == "")
     errorMessage = "Put some data in Dummy !!";

    //if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) <= 0){
    //  errorMessage = "You must enter a positive value for your bid amount.";      
    //} else if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) < AppSettings.MIN_BID_AMOUNT && !this.selectedIsForLots) {
    //  errorMessage = "The bid amount must be greater or equal to " + AppSettings.MIN_BID_AMOUNT;
    //} 
    return errorMessage;
  }


  public saveFormData()
  {
    let formData:FormData = new FormData();
    formData.set("Warehouse", this.warehouse);
    formData.set("Location", this.location);
    formData.set("Weight", this.weight.toString());
    formData.set("Reference",this.poNumber);
    if (this.referenceItem != null)
      formData.set("ReferenceItem",this.referenceItem)
   
    let errorString = this.validate(formData);

    if (errorString.length == 0) {
      this.http
        .post(this.baseUrl + 'Navara/SaveGRN', formData)
        .subscribe({
          next: (response) => this.saveFinished(),
          error: (error) => this.sm.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
        });
      //this.reload();  

    }else{
      this.sm.metroAlert(errorString, "The dialog contains errors", true);
    }
    
  }
  

  saveFinished()
  {
    this.sm.metroAlert("We have saved the form.","Title");
    this._router.navigate(['/milling/testing/home'])
  }
}
