import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;


@Component({
  selector: 'app-rail-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class RailComponent  implements OnInit{
  private sm : SharedModule;

  openTrains  : any = null;
  closedTrains : any = null;
  siteName : string = "";

  
 dataLoaded : boolean = false;

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
  }


  ngOnInit(){
    let counter : number = 0;
    this.http.get(this.baseUrl + 'Rail/ListTrains').subscribe(
      (data:any) => {
        this.openTrains = data; 
        counter++;
        this.dataLoaded = counter == 2});

        this.http.get(this.baseUrl + 'Rail/ClosedTrains').subscribe(
          (data:any) => {
            this.closedTrains = data; 
            counter++;
            this.dataLoaded = counter==2});
        
        
            
    }
}
