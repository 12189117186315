<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
</ul>
<h1>Packing Record</h1>
<ng-container *ngIf="dataLoaded">
    <form>
        <div class="row">
            <div class="cell-5">
                <label>Works Order No</label>
                <select data-role="select" id="System"   [(ngModel)]="selectedWO" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let c of worksOrders" [ngValue]="c.woOrder">{{c.description}}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="cell-5">
                <label>Location of Origin (Bin or Flaker)</label>
                <select data-role="select" id="System"   [(ngModel)]="selectedValue" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let c of locations" [ngValue]="c.code">{{c.description}}</option>
                </select>
            </div>

            <div class="cell-5">
                <label>Part ID</label>
                <input id="partID" type="text" class="metro-input">
            </div>
        </div>
        
        <div class="row">
            <div class="cell-5">
                <label>No of Bags</label>
                <input id="NoOfBags" type="number" class="metro-input" value = "1">
            </div>

            <div class="cell-5">
                <label>Weight</label>
                <input id="weight" type="number" class="metro-input" value = "0.00">
            </div>
        </div>
        
        
        <div class="row">
            <div class="cell-5">
                <label>Jordans Pallet No</label>
                <input id="ContainerID" type="text" class="metro-input" >
            </div>

            <div class="cell-5">
                <label>Barcode Scan</label>
                <input id="palletID" type="text" class="metro-input" >
            </div>
           
        </div>
    
        
        <div class="row">
            <div class="cell">
                <button class="button primary" (click)="saveFormData()">Save</button>
            </div>
        </div>
    </form>
    
 </ng-container>