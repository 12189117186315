<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Home</a></li>    
</ul>

<h1>Reports</h1>
<div>
    <tr-viewer
    [containerStyle]="viewerContainerStyle"
    [serviceUrl]="'http://localhost:5000/api/reports'"
    [reportSource]="{
        report: 'Product Catalog.trdp',
        parameters: {}
    }"
    [viewMode]="'INTERACTIVE'"
    [scaleMode]="'SPECIFIC'"
    [scale]="1.0">
</tr-viewer>


</div>



