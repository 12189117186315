import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from "./auth/auth.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { SharedModule } from './shared/shared.module';
import { Router } from "@angular/router";
declare var $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title : string = "";
  systemType : string = "";
  sm1 : SharedModule;
  
  

  constructor(private router: Router, public auth: AuthService, private http:HttpClient,@Inject('BASE_URL') private baseUrl: string, public sm : SharedModule) {
    
      this.sm1 = sm;
      
      this.SetSystemStart();

  }

  ngOnInit()
  {
    this.http.get(this.baseUrl + 'SiteSettings/systemType', {responseType: 'text'}).subscribe(
      data => 
        localStorage.setItem('systemType', data)
    
      );
  }

  SetSystemStart ()
  {
    if (this.sm1.GetSystemType() != "MILLING")
    {
        if (!this.auth.isLoggedIn) 
        {
              if (this.router.url !== '/login')
                this.router.navigate(['/login']) ;   
        }
    }
    else
      {this.router.navigate(['/']);}
  }
  

  
}

