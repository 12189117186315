import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-analysis-add',
  templateUrl: './analysis-add.component.html',
  styleUrls: ['./analysis-add.component.css']
})
export class AnalysisAddComponent  implements OnInit{
  dataLoaded = false;
  analysisLoc : any;
  selectedValue : string = "";
  moisture : number = 0;
  sg : number = 0;
  kiln :any[] = [
    {id: 'KILN1', text: 'Kiln 1'},
    {id: 'KILN2', text: 'Kiln 2'},
   ];

  selectedKiln : string = "";


  private sm : SharedModule;
  
  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
  }

  ngOnInit(){
    this.http.get(this.baseUrl + 'Milling/GetAnalysisLocations').subscribe(
      (data:any) => {
        this.analysisLoc = data; 
        this.dataLoaded = true;});
  }

  validate(formData: FormData) {
    let errorMessage:string = "";
   if (this.selectedValue == "")
     errorMessage = "Please select a valid location";
   if (this.moisture == 0)
     errorMessage = "Please add a valid moisture value";
   if (this.sg == 0)
     errorMessage = "Please add a valid specific gravity value";
  if (this.selectedKiln == "")
     errorMessage = "Please select a valid kiln";
    return errorMessage;
  }

  public cancelForm()
  {
    this.sm.metroAlert("Cancelled all changes","Cancel");
    this._router.navigate(['/'])
  }


  public saveFormData()
  {
    let formData:FormData = new FormData();
    formData.set("Location",this.selectedValue);
    formData.set("Moisture",(this.moisture.toString()));
    formData.set("SG",(this.sg.toString()));
    formData.set("SystemID", this.selectedKiln);
    let errorString = this.validate(formData);

    if (errorString.length == 0) {
      this.http
        .post(this.baseUrl + 'Milling/SaveAnalysisRecord', formData)
        .subscribe({
          next: (response) => this.saveFinished(),
          error: (error) => this.sm.metroAlert("There was an error and your site settings have not been saved.<br/><br/>" + error.message, "Failed", true),
        });
     
    }else{
      this.sm.metroAlert(errorString, "The dialog contains errors", true);
    }
    
  }
  

  saveFinished()
  {
    this.sm.metroAlert("System settings saved.","Save Data");
    this._router.navigate(['/'])
  }
}
