<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
    <li><a [routerLink]="['/milling/analysisAdd']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Add Analysis</a></li>    
</ul>
<h1>Active PLC Connections</h1>

<ng-container *ngIf="!dataLoaded">
    <div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your
        data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
    </ng-container>
    
    <ng-container *ngIf="dataLoaded">
        <table class="table striped table-border mt-4 subcompact  " id="connections" data-role="table" data-pagination="true" data-show-search="true" data-rows-steps="25" data-rows="25" data-show-rows-steps="false">
            <thead>
              <tr>
              <th class="sortable-column sort-asc" >Connection Name</th>
              <th class="sortable-column sort-asc" >Status</th>
              <th class="sortable-column sort-asc" >Date Time of last Status</th>
              </tr>
            </thead>
            <tbody row-hover >
              <tr *ngFor="let c of activeRec">
                <td>{{c.name}}</td><td>{{c.status}}</td><td>{{c.dtStatus}}</td>
              </tr>
            </tbody>
          </table>
        
    </ng-container>