<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
</ul>

<h1>Plant Data</h1>
<ng-container *ngIf="!dataLoaded">
<div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your
    data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
</ng-container>

<ng-container *ngIf="dataLoaded">
<form name="edit" id="edit-buy" class="" action="" method="post">
    <div class="grid">
        <div class="form-group" >
            <div class="row">
                <div class="cell-6">
                    <label>Plant Description</label>
                    <input id="pltDescription" type="text" class="metro-input"  [(ngModel)]="pltDescription" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="row">
                               
                <div class="cell-3">
                    <label>Current Active System</label>
                    <select data-role="select" id="System"   [(ngModel)]="selectedValue" [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let c of systemTypes" [ngValue]="c.id">{{c.name}}</option>
                    </select>
                </div>
                <div class="cell-3">
                    <label>Plant Purchase Order</label>
                    <input id="poNumber" type="text" class="metro-input"  [(ngModel)]="poNumber" [ngModelOptions]="{standalone: true}">
                </div>
            </div>

            
        </div>
    </div>
    <div class="dialog-actions">
        <button class="button" type="reset" value="Reset" (click)="cancelForm()">Cancel</button>
        <button class="button primary" (click)="saveFormData()">Save</button>
      </div>
</form>

</ng-container>
