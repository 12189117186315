<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
</ul>

<h1>Day Report</h1>
    <ng-container *ngIf="!dataLoaded">
    <div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your
        data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
    </ng-container>


    <ng-container *ngIf="dataLoaded">
        <form name="edit" id="edit-buy" class="" action="" method="post">
            <div class="grid">
                <div class="form-group" >
                    <div class="row">
                        <div class="cell-6">
                            <label>Enter Date From (dd/mm/yyyy)</label>
                            <input id="reportDate" type="text" class="metro-input"  [(ngModel)]="reportDate" [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog-actions">
                <button class="button primary" (click)="showReport()">Show Report</button>
              </div>
        </form>
        
        </ng-container>
 
        <embed id="pdfSrc" [attr.type]="'application/pdf'" width="100%" height="800px"/>
          
       