<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
</ul>
<h1>Locations</h1>

<ng-container *ngIf="!dataLoaded">
    <div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your
        data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
    </ng-container>
    

<ng-container *ngIf="dataLoaded">
    <table class="table striped table-border mt-4 subcompact  " id="locations" data-role="table" data-pagination="true" data-show-search="true" data-rows-steps="25" data-rows="25" data-show-rows-steps="false">
        <thead>
          <tr>
          <th class="sortable-column sort-asc" >Code</th>
          <th class="sortable-column sort-asc" >Description</th>
          <th class="sortable-column sort-asc">Part ID</th>
          <th class="sortable-column sort-asc">Cubic Capacity</th>
          <th class="sortable-column sort-asc">Specific Gravity</th>
          <th class="sortable-column sort-asc">Radar Value</th>
          <th class="sortable-column sort-asc">Last Update</th>
          </tr>
        </thead>
        <tbody row-hover >
          <tr *ngFor="let c of locations">
            <td>{{c.code}}</td><td>{{c.description}}</td><td>{{c.partID}}</td><td>{{c.cubicCapacity}}</td><td>{{c.sg}}</td><td>{{c.radarReading}}</td><td>{{c.dtLastUpdate}}</td>
          </tr>
        </tbody>
      </table>
    
</ng-container>
